<template>
  <div v-loading="load">
    <el-row
      :gutter="10"
      class="padded"
    >
      <el-col
        :xs="24"
        :sm="12"
        :md="12"
      >
        <div style="background: #f0f0f0; color:#000000; padding: 10px;">
          <strong>Kindly follow the steps below to upload questions in bulk (per subject) from csv file.</strong>
          <ol>
            <li><code><a @click="handleDownload">Download CSV Format</a></code> to get the file format</li>
            <li>Open with a spreadsheet package, example Microsoft Excel package</li>
            <li>Leave the Table Header Names the way they are and <strong class="red">DO NOT MODIFY THEM</strong></li>
            <li>Fill the downloaded csv file accordingly as guided by the Header Names. Each row for an entry. <br><strong class="red">DO NOT leave any cell blank.</strong></li>
            <li>When done with all entries, SAVE your file with .csv (Comma delimited) file extension. <strong class="red">For example Math_Questions.csv</strong></li>
            <li>Fill the form accordingly and select the file from where you saved</li>
            <li>Preview your work and click on SUBMIT if satisfied</li>
            <li>CONGRATULATIONS!!! You are done.</li>
          </ol>
        </div>
      </el-col>
      <el-col
        :xs="24"
        :sm="12"
        :md="12"
      >
        <b-row>
          <b-col
            cols="12"
            class="mb-2"
          >
            <b-alert
              variant="primary"
              show
            >
              <div class="alert-body">
                <h5><strong>Fill the Form below</strong></h5>
              </div>
            </b-alert>
          </b-col>
          <b-col
            md="12"
          >
            <b-form-group
              label="Select Class"
              label-for="level"
            >
              <el-select
                v-model="selectedClassTeacher"
                style="width: 100%"
                value-key="id"
                filterable
                @input="setSubjects()"
              >
                <el-option
                  v-for="(each_class_teacher,index) in class_teachers"
                  :key="index"
                  :value="each_class_teacher"
                  :label="each_class_teacher.c_class.name"
                />
              </el-select>
            </b-form-group>
          </b-col>
          <b-col
            md="12"
          >
            <b-form-group
              label="Select Subject"
              label-for="class"
            >
              <el-select
                id="class"
                v-model="selectedSubjectTeacher"
                value-key="id"
                filterable
                style="width: 100%;"
                placeholder="Select Subject"
              >
                <el-option
                  v-for="(subjectTeacher, index) in subjectTeachers"
                  :key="index"
                  :label="subjectTeacher.subject.name"
                  :value="subjectTeacher"
                />
              </el-select>
            </b-form-group>
          </b-col>
          <b-col md="12">
            <upload-excel-component
              :on-success="handleSuccess"
            />
          </b-col>
        </b-row>
      </el-col>
    </el-row>
    <legend v-if="tableData.length > 0">
      Preview what you just uploaded and then click on the submit button. <a
        class="btn btn-success"
        @click="submitBulkQuestion"
      >SUBMIT</a>
    </legend>
    <div style="height: 600px; overflow:auto;">
      <el-table
        :data="tableData"
        border
        highlight-current-row
        style="width: 100%;margin-top:20px;"
      >
        <el-table-column
          v-for="item of tableHeader"
          :key="item"
          :prop="item"
          :label="item"
        />
      </el-table>
    </div>
    <legend v-if="tableData.length > 0">
      Click on the submit button. <a
        class="btn btn-success"
        @click="submitBulkQuestion"
      >SUBMIT</a>
    </legend>
  </div>

</template>

<script>
import {
  BAlert,
  BRow,
  BCol,
  BFormGroup,
} from 'bootstrap-vue'
import UploadExcelComponent from '@/views/components/UploadExcel/index.vue'
import Resource from '@/api/resource'

export default {
  name: 'UploadBulk',
  components: {
    BAlert,
    BRow,
    BCol,
    BFormGroup,
    UploadExcelComponent,
  },
  // props: {
  //   params: {
  //     type: Object,
  //     default: () => ({}),
  //   },
  //   itemsInStock: {
  //     type: Array,
  //     default: () => ([]),
  //   },

  //   bulkUpload: {
  //     type: Boolean,
  //     default: () => ({
  //       option: true,
  //     }),
  //   },
  //   page: {
  //     type: Object,
  //     default: () => ({
  //       option: 'add_new',
  //     }),
  //   },
  // },
  data() {
    return {
      fill_fields_error: false,
      tableData: [],
      tableHeader: [],
      form: {
        subject_teacher_id: '',
        teacher_id: '',
        bulk_data: [],
      },
      class_teachers: [],
      selectedClassTeacher: {},
      subjectTeachers: [],
      selectedSubjectTeacher: {},
      load: false,

    }
  },
  created() {
    this.fetchClassesWithTheirSubjects()
  },
  methods: {
    fetchClassesWithTheirSubjects() {
      const app = this
      app.load = true
      const classesResource = new Resource('school-setup/fetch-classes-with-their-subjects')
      classesResource.list() // back end route from web.php

        .then(response => {
          app.load = false
          app.class_teachers = response.class_teachers
        }).catch(() => {
          app.load = false
        })
    },
    setSubjects() {
      const app = this
      app.subjectTeachers = app.selectedClassTeacher.subject_teachers
    },
    handleDownload() {
      // this.downloadLoading = true
      import('@/vendor/Export2Excel').then(excel => {
        // const multiHeader = [['List of Products', '', '', '', '']];
        const tHeader = ['QUESTION', 'OPTA', 'OPTB', 'OPTC', 'OPTD', 'ANSWER']
        // const filterVal = []
        // const list = [] // this.items
        const data = [] // this.formatJson(filterVal, list)
        excel.export_json_to_excel({
          // multiHeader,
          header: tHeader,
          data,
          filename: 'Bulk Question Upload Sample',
          autoWidth: true,
          bookType: 'csv',
        })
        // this.downloadLoading = false
      })
    },
    handleSuccess({ results, header }) {
      this.tableData = results
      this.tableHeader = header
      // console.log(results);
    },
    submitBulkQuestion() {
      const app = this
      const { form } = app
      form.subject_teacher_id = app.selectedSubjectTeacher.id
      form.teacher_id = app.selectedSubjectTeacher.teacher_id
      if (form.subject_teacher_id === '') {
        app.$message({ message: 'Please select a subject to continue', type: 'danger' })
      } else {
        app.load = true
        form.bulk_data = app.tableData

        const uploadBulkQuestions = new Resource('lms/store-bulk-questions')
        uploadBulkQuestions.store(form)
          .then(response => {
            app.load = false
            app.tableData = response.unsaved_data
            if (app.tableData.length > 0) {
              app.$alert('Some data could not be uploaded. Possible cause may be duplicate entries')
            } else {
              app.tableHeader = []
              app.$message({ message: 'Bulk upload Successful!!!', type: 'success' })
            }
          })
          .catch(error => {
            app.load = false
            app.$message({ message: error, type: 'danger' })
          })
      }
    },

  },
}
</script>
<style scoped>
  .red {
    color: #c40505;
  }
</style>
